import { render, staticRenderFns } from "./tariffCompare.vue?vue&type=template&id=3c356bc1"
import script from "./tariffCompare.vue?vue&type=script&lang=js"
export * from "./tariffCompare.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports